$fontBase: 16px
$fontSmall: 0.74rem
$fontRegular: 0.9rem
$fontMedium: 1rem
$fontBig: 2.7rem
$fontGiant: 3.8rem

$fontParagraph: $fontRegular
$fontParagraphDesktop: 1.42rem
$fontHero: 1.85rem
$fontHeroDesktop: $fontGiant
$fontH1: 2.3rem
$fontH2: 1.61rem
$fontH2Desktop: $fontBig
$fontIcon: 1.6rem
$fontMobileMenu: $fontMedium
