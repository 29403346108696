.treasureCard
  .thumbnail
    overflow: hidden
    height: 115px
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer

    img
      object-fit: cover
      width: 100%
      height: 100%

