@import 'styles/units.sass'
@import 'styles/font-sizes.sass'
@import 'styles/colors.sass'

.cardHeader
  min-height: 115.75
  display: flex
  flex-direction: column

  .titleRow
    display: flex
    align-items: center
    justify-content: space-between

  a
    color: inherit
    overflow: hidden
    text-overflow: ellipsis
    margin-right: $margin


  a:hover
    color: $gray3

.iconButton
  cursor: pointer
  width: 16px
  display: flex
  align-items: center
  justify-content: center

  &:hover svg path
    color: $gray3
    
