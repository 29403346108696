@import 'styles/units.sass'
@import 'styles/colors.sass'
@import 'styles/font-sizes.sass'

.content
  margin: $margin*2 $margin*3

.header
  display: flex
  align-items: center
  margin-bottom: $margin*2

  h1
    font-size: $fontH1
    margin: 0

.form
  max-width: 500px
