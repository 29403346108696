@import 'styles/units.sass'
@import 'styles/breakpoint.sass'
@import 'styles/colors.sass'

.menu
  display: none

  @include breakpoint($tablet)
    display: flex

  align-items: center
  justify-content: flex-end
  margin-right: $marginPage

  .loginButton
    margin-right: $marginMenu
    background: none
    color: inherit
    border: none
    padding: 0
    font: inherit
    cursor: pointer
    outline: inherit

    &:hover
      transition: color 0.3
      color: $brand

.mobileMenu
  height: 100%

  @include breakpoint($tablet)
    display: none
