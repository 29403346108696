@import 'styles/colors.sass'
@import 'styles/units.sass'

.layout
  min-height: 100vh
  min-width: $globalMinWidth
  overflow-x: hidden

  > main
    margin-top: $menuHeight

.footer
  text-align: center
  background-color: $dark1
  color: $white

