@use 'sass:math'
@import 'styles/colors.sass'
@import 'styles/units.sass'

.form
  :global(.ant-input::placeholder)
    color: $placeholderGray

  :global(.ant-picker-input > input::placeholder)
    color: $placeholderGray

  :global(.ant-picker)
    width: 100%

.inputButton
  cursor: pointer
  display: flex
  flex-direction: row
  align-items: center
  padding: $margin 0
  transition: background 0.3s ease

  &:hover
    background: $gray6

  :global(.anticon)
    margin-right: $margin * 2
    margin-left: $margin

    svg
      width: 1.5 * $margin
      height: 1.5 * $margin

.formInputButtonWrapper
  :global(.ant-form-item)
    margin-bottom: 0px

.mapWrapper
  height: 100%

.locationSelectorModal
  width: 90vw !important
  height: 90vh !important
  top: 5vh
  padding-bottom: 0px

  :global(.ant-modal-content)
    height: 100%

  :global(.ant-modal-body)
    padding: 0 !important
    height: calc(100% - 106px)

.buttons
  display: flex
  justify-content: flex-end
  margin-top: $margin * 3

  button
    margin-left: math.div($margin, 2)

.errorContainer
  margin-bottom: $margin
