@import 'styles/units.sass'
@import 'styles/colors.sass'
@import 'styles/font-sizes.sass'

.title
  display: flex
  align-items: center

  :global
    span.anticon-crown
      margin-right: $margin
      color: $warning

      svg
        width: 24px
        height: 24px

