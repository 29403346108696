@use 'sass:math'
@import 'styles/colors.sass'
@import 'styles/units.sass'

.sider
  overflow: auto
  height: 100vh
  position: relative
  background-color: $dark2

.siderMenu
  position: absolute
  width: 100%
  background-color: $dark2

.upgradeIcon
  color: $lightBlue1

.upgradeIcon:hover
  color: $midBlue1

.upgradeIcon svg
  filter: drop-shadow(0 0 7px darken($lightBlue1, 25%))

.logo
  color: $brand
  display: flex
  align-items: center
  cursor: pointer
  margin-top: $margin * 2
  margin-bottom: $margin * 2

  svg
    width: 24px

.expanded
  justify-content: start
  margin-right: 16px
  margin-left: 25px

.collapsed
  margin-left: 28px
