@import 'styles/units.sass'
@import 'styles/colors.sass'

.layout
  min-height: 100vh
  min-width: $globalMinWidth
  overflow-x: hidden

  > main
    margin-top: $menuHeight

.contentLayout
  height: 100vh
  overflow: scroll
  display: flex

.contentWrapper
  height: 100%

.alert
  background: $midBlue1
  color: $white
  padding: $margin $margin*2
  display: flex
  align-items: flex-start

  > div
    flex: 1
    margin-right: $margin*2

    a
      color: $white
      text-decoration: underline

  button
    cursor: pointer
    background: none
    color: inherit
    border: none
    padding: 0
    font: inherit
    outline: inherit
