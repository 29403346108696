@import 'styles/units.sass'
@import 'styles/font-sizes.sass'

.content
  margin: $margin*2 $margin*3

  h1
    font-size: $fontH1
    flex: 1
    margin: 0
