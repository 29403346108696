@import 'styles/units.sass'
@import 'styles/font-sizes.sass'
@import 'styles/colors.sass'

.content
  text-align: center
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

  .icon
    margin-bottom: $margin

    img
      height: 64px

  h1
    font-size: 2.2rem
    margin-bottom: 0

  h2
    font-size: 1.2rem
    font-weight: 600

.priceCard
  margin-right: $margin
  margin-left: $margin
  max-width: 320px

.highlight
  border-radius: $borderRadiusDefault
  padding-bottom: $margin * 2
  background: white

  .highlightNote
    height: $margin * 1.5
    border-radius: $borderRadiusDefault
    background-color: $brand
    color: $white
    text-transform: uppercase
    font-size: $fontSmall
    font-weight: 600
    display: flex
    align-items: center
    justify-content: center
