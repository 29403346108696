@use 'sass:math'
@import 'styles/units.sass'
@import 'styles/colors.sass'
@import 'styles/font-sizes.sass'

// General
.wrapper
  position: relative
  display: flex
  flex-direction: column
  height: 100%

h1.huntTitle
  font-size: $fontH1
  margin: 0
  max-width: 50vw
  overflow: hidden
  text-overflow: ellipsis

.editButton
  cursor: pointer
  font-size: $margin*1.5
  margin-right: $margin

.viewButtons
  margin-left: auto
  display: flex
  align-items: center

  :global
    .ant-btn:last-child
      margin-left: math.div($margin, 4)

.hide
  display: none

// Card view
.cardContent
  margin: $margin*2 $margin*3 0 $margin*3
  position: inherit

  :global
    .ant-card-meta-detail
      width: 100%

  .row1
    display: flex
    align-items: center
    height: 32px

    .breadcrumbs
      flex: 1

  .row2
    display: flex
    align-items: center
    margin-top: math.div($margin, 2)
    margin-bottom: $margin*2

    h1
      font-size: $fontH1
      margin: 0
      max-width: 50vw
      overflow: hidden
      text-overflow: ellipsis

    .viewButtons
      button.selected
        background: $lightBlue1
        border-color: $midBlue1
        color: $black

      button:not(.selected)
        background: transparent
        border-color: $midBlue1
        color: $midBlue1

        &:hover
          color: $black

    .editButton
      color: $gray3

// Map view
.column1
  margin: $margin*2 0 0 $margin*3
  position: absolute
  left: 0
  z-index: 1

  .breadcrumbs
    height: 32px
    display: flex
    align-items: center

    span, a
      color: $white
      text-shadow: 1px 1px 8px black

  h1
    color: white
    text-shadow: 1px 1px 8px black
    padding-top: math.div($margin, 2)

.column2
  margin: $margin*2 $margin*3 0 0
  position: absolute
  right: 0
  z-index: 1

  .editButton
    color: $white

  .viewButtons
    justify-content: flex-end
    height: 57.83333px
    margin-top: math.div($margin, 2)

  :global(.ant-btn.ant-btn-icon-only).selected
    border-color: $lightBlue1
    color: $lightBlue1

  :global
    .ant-btn-primary
      background-color: $brand2
      border-color: $brand2

    .ant-btn.ant-btn-icon-only
      background: transparent
      border-color: $white
      color: $white

      span
        color: $white

        &:hover
          color: $lightBlue1

.noTreasures
  text-align: center
  margin-top: $margin * 4
  display: flex
  flex-direction: column
  align-items: center

  h2
    width: 30vw
    min-width: 250px
    color: $gray7

  svg
    height: 200px
    margin-bottom: $margin*2
