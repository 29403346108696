@use 'sass:math'
@import 'styles/colors.sass'
@import 'styles/units.sass'

.form
  :global
    .ant-input::placeholder
      color: $placeholderGray

    .ant-picker-input > input::placeholder
      color: $placeholderGray

    .ant-picker
      width: 100%

  .upload
    .coverPicture
      position: relative
      width: 472px
      height: 236px
      cursor: pointer

      img
        object-fit: cover
        width: 100%
        height: 100%

    .coverCaption
      opacity: 0
      position: absolute
      z-index: 2
      top: 0
      left: 0
      width: 100%
      height: 100%
      display: flex
      align-items: center
      justify-content: center
      color: $white
      background-color: rgba(0, 0, 0, 0.6)
      transition: opacity 0.3s

      &:hover
        opacity: 1

      > span
        margin-left: math.div($margin, 2)

.buttons
  display: flex
  justify-content: flex-end
  margin-top: $margin * 3

  button
    margin-left: math.div($margin, 2)

.errorContainer
  margin-bottom: $margin
