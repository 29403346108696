@use 'sass:math'
@import 'styles/units.sass'

.treasureCards
  display: grid
  grid-gap: math.div($margin, 2)
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr))

  :global(.ant-card)
    height: 100%

.wrapper
  margin-bottom: $margin*2

.pagination
  margin-top: $margin*2
  text-align: center
