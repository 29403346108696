$margin: 15px
$marginPage: 40px

$marginMenu: $margin * 1.5
$marginContentTop: $margin * 2

$globalMinWidth: 250px
$mobileContentWidth: 85vw
$desktopContentWidth: 80vw
$desktopContentMaxWidth: 900px
$menuHeight: 64px
$simplifiedMenuHeight: 52px
$huntCardHeight: $margin * 15

$formWidth: $margin * 29
$formItemVerticalSpacing: 24px

$borderRadiusDefault: 2px
