@import 'styles/units.sass'
@import 'styles/breakpoint.sass'
@import 'styles/colors.sass'
@import 'styles/font-sizes.sass'

.hamburgerMenu
  position: relative
  height: 100%
  display: flex
  align-items: center
  justify-content: flex-end
  margin-right: $marginMenu
  z-index: 2

  @include breakpoint($tablet)
    display: none

.hamburgerMenuClosed
  &> div > div
    background-color: $gray3 !important

.hamburgerMenuOpen
  &> div > div
    background-color: $white !important

.slideInMenu
  position: absolute
  top: 0
  background: $dark2
  z-index: 1
  transition: left 0.3s ease
  width: 100vw
  height: 100vh

  .menuWrapper
    margin: $margin * 4 $margin * 2

    :global(.anticon)
      min-width: $fontMobileMenu
      font-size: $fontMobileMenu

    :global(.ant-menu)
      font-size: $fontMobileMenu

    :global(.ant-menu-item:active)
      background: $dark3

.slideInMenuOpen
  left: 0


.slideInMenuClosed
  left: -100vw

