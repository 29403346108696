@import 'styles/colors.sass'

.base
  display: inline-block

  :global
    .ant-btn
      background: transparent

    .ant-btn-dangerous:hover
      background: transparent

    .ant-btn-dangerous
      background: transparent

.blue
  :global
    .ant-btn-default:not(.ant-btn-dangerous)
      color: $midBlue1
      border-color: $midBlue1

    .ant-btn-default:not(.ant-btn-dangerous):hover
      color: $midBlue1
      border-color: $midBlue1
      background: transparent

.brand
  :global
    .ant-btn-default:not(.ant-btn-dangerous)
      color: $brand
      border-color: $brand

    .ant-btn-default:not(.ant-btn-dangerous):hover
      color: $brand2
      border-color: $brand2
      background: transparent
