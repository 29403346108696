@import 'styles/units.sass'
@import 'styles/font-sizes.sass'
@import 'styles/colors.sass'

.wrapper main
  width: 100%
  // margin-top: $simplifiedMenuHeight
  // min-height: calc(100vh - $simplifiedMenuHeight)
  // min-height: 100vh

  .headings
    // margin-top: $simplifiedMenuHeight + $margin * 2
    margin-top: $margin * 2

    h1, h2
      margin: 0
      text-align: center
      color: $white

    h1
      font-size: $fontHero

    h2
      font-size: $fontBase
      font-weight: 400
      margin-top: $margin

.content
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: center
  min-height: 100vh
  background: url('/assets/images/background-dark.webp')
  background-attachment: fixed
  color: $white

.upgradeTiers
  margin: $margin * 2 0

.goBack
  margin-top: $margin
  margin-left: $margin * 4
  cursor: pointer
  user-select: none
  
