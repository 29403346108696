@use 'sass:math'
@import 'styles/units.sass'
@import 'styles/colors.sass'
@import 'styles/font-sizes.sass'

.content
  padding: $margin*2 $margin*3

.header
  margin-bottom: $margin*2

  .firstRow
    display: flex
    align-items: center

  .divider
    margin: 0 $margin*2
    border-left: 1px solid $gray5
    height: $margin

  h1
    font-size: $fontH1
    flex: 1
    margin: 0

  .selectOrder
    margin-top: $margin
    margin-left: auto

    &> span
      margin-right: math.div($margin, 2)

    :global(.ant-select)
      width: $margin*9

.hunts
  display: grid
  grid-gap: $margin
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))

  :global
    .ant-card
      height: 100%
    .ant-card-meta-detail
      width: 100%

.pagination
  margin-top: $margin * 2
  text-align: center

.skeleton
  display: grid
  grid-gap: $margin * 2
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))

.cardMeta
  width: 100%

.upgradeButton
  margin-left: math.div($margin, 2)

.noHunts
  text-align: center
  margin-top: $margin * 4
  display: flex
  flex-direction: column
  align-items: center

  h2
    color: $gray7

  p
    width: 30vw
    min-width: 250px
    color: $gray8

  svg
    height: 200px
    margin-bottom: $margin*2
