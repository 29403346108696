@import 'styles/units.sass'
@import 'styles/colors.sass'

.content
  margin: $margin*2 $margin*3

.firstRow
  display: flex
  justify-content: space-between
  align-items: center

.editButton
  cursor: pointer
  font-size: $margin*1.5
  color: $gray3

.errorContainer
  margin-top: $margin
  margin-bottom: $margin
