@import 'styles/units.sass'

.coverPicture
  overflow: hidden
  height: $huntCardHeight
  cursor: pointer
  position: relative

  display: flex
  align-items: center
  justify-content: center

  img
    object-fit: cover
    width: 100%
    height: 100%


