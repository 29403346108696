@use 'sass:math'
@import 'styles/units.sass'
@import 'styles/colors.sass'
@import 'styles/font-sizes.sass'

.content
  margin-top: $margin

  :global
    .swiper-button-prev, .swiper-button-next
      color: $gray1

    .swiper-button-prev
      margin-left: $margin

    .swiper-button-next
      margin-right: $margin

    .swiper-pagination-bullet-active
      background-color: $gray1

    .swiper-pagination-bullet
      background-color: $gray1

.header
  display: flex
  align-items: center
  margin-top: $margin

  h1
    font-size: $fontH1
    margin: 0

.subHeader
  display: flex
  color: $gray5
  font-style: italic
  margin-bottom: $margin

  > div
    margin-right: $margin*2

    :global(.anticon)
      margin-right: math.div($margin, 2)

.carouselSlide
  background: $black2
  display: flex
  align-items: center
  justify-content: center
  height: 80vh

  img
    max-height: 100%
    max-width: 100%
    object-fit: contain
    cursor: pointer

