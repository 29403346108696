$white: #f7fafc
$white2: #f2f2f2
$gray1: #d6e3ed
$gray2: #707a88
$gray3: #8595b3
$gray4: #313131
$gray5: #b5b5b5
$gray6: #ecf4fb
$gray7: #60606c
$gray8: #8c8ca2
$black: #2f2f2f
$black2: #3f3f3f
$brand: #3d9a74
$brand2: #46bc8c
$brand3: #46bc8c
$borderLight: #f0f0f0
$dark1: #001623
$dark2: #001529
$dark3: #052746
$dark4: #040b0f
$warning: #faad14
$red1: #c21546
$midBlue1: #2491ae
$lightBlue1: #a3dfff

$background1: $gray1
$background2: $white
$footerBackground: $dark1
$placeholderGray: $gray4
