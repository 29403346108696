@import 'styles/units.sass'
@import 'styles/colors.sass'

.wrapper
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

  :global
    .ant-card-body
      padding: $margin * 4 $margin * 3

    .ant-input::placeholder
      color: $placeholderGray

.prices
  display: flex
  align-items: center
  justify-content: center

  button
    width: 160px

.yearlyOption
  width: 100%
  max-width: 1050px
  padding: 0 $margin

  border-radius: $borderRadiusDefault
  display: flex
  align-items: center
  justify-content: flex-end

  span
    margin-right: $margin
    font-weight: 600
    color: $white

.spin
  margin-top: $margin * 2
  margin-bottom: $margin
  height: $margin * 2
