@use 'sass:math'
@import 'styles/units.sass'
@import 'styles/colors.sass'
@import 'styles/font-sizes.sass'

.content
  margin: $margin*2 $margin*3

  main
    h2
      margin-bottom: $margin
      margin-top: $margin*2

.header
  display: flex
  align-items: center
  margin-bottom: $margin*2

  h1
    font-size: $fontH1
    margin: 0

.modalWrapper
  :global
    .ant-modal-confirm-warning .ant-modal-confirm-body > .anticon, .ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon
      color: #ff4d4f

.buttons
  margin-top: $margin

  :global
    .ant-btn
      margin-right: math.div($margin, 2)

.paymentPending > span
  display: inline-block
  margin-left: math.div($margin, 2)
