@use 'sass:math'
@import 'styles/colors.sass'
@import 'styles/units.sass'

.header
  background: $white
  display: flex
  padding: 0
  position: fixed
  top: 0
  left: 0
  width: 100vw
  z-index: 3

.logo
  padding-left: $marginPage
  display: flex
  align-items: center
  font-size: 1.1rem
  cursor: pointer
  color: $brand
  transition: color 0.3s

  svg path
    transition: fill 0.3s

  &:hover
    color: $brand3

  &:hover svg path
    fill: $brand3
      

  svg
    width: 43px
    height: 43px
    margin-right: math.div($margin, 2)

.menu
  flex: 1

.logo, .menu
  border-bottom: 1px solid $borderLight
