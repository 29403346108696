@import 'styles/units.sass'

.form
  width: 100%

.alertsWrapper
  margin-bottom: $margin

  :global(.ant-alert-message)
    display: none

.reCaptcha
  margin-bottom: $margin
